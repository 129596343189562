@import "variables";

body {
  //font-family: $base-font-family;
  line-height: 1.42857;
}

h1,
h2 {
  font-family: $headline-font-family;
  font-weight: bold;
  text-transform: uppercase;
}

h1 {
  font-size: 30px;
  margin: 22px 0 36px;
}

h2 {
  font-size: 18px;
  margin: 0 0 1em;

  mat-card-title & {
    margin: 0;
  }
}

h3 {
  font-weight: bold;
  margin: 1em 0 0.5em;
}

strong {
  font-weight: bold;
  color: $text-data-color;
}
a strong {
  color: $primary-action;
}
