@import "ng-pick-datetime/assets/style/picker.min.css";
@import "variables";

/* Original plugin: https://github.com/DanielYKPan/date-time-picker */
/* Custom style applied for the original plugin */
.owl-dt-popup-container {
  border-radius: 7px;

  .owl-dt-calendar-control {
    padding: 14px;
    font-size: 16px;
  }

  .owl-dt-calendar-cell-selected {
    font-weight: 900;
    background-color: $primary-action;

    &.owl-dt-calendar-cell-today {
      box-shadow: none;
      background-color: $primary-action;
    }
  }
  .owl-dt-calendar-cell-today {
    box-shadow: inset 0 0 0 2px #999;
    font-weight: 900;
    border: none;
  }

  .owl-dt-timer {
    padding: 0.3em;
    height: 6em;

    .owl-dt-timer-input {
      border: 0px;
      font-size: 1.1em;
    }
  }

  .owl-dt-timer-hour12-box {
    color: $primary-action;

    .owl-dt-control-button-content {
      &:hover {
        background-color: $primary-action;
      }
    }
  }

  .owl-dt-container-buttons {
    padding: 10px;
    height: auto;

    .owl-dt-control-button {
      padding: 10px;
      color: $primary-action;

      &:first-child {
        margin-right: 3px;
      }
      &:last-child {
        color: $text-action-icon-color;
        background-color: $action-icon-bg-color;
        margin-left: 3px;
      }

      &:hover,
      &:focus {
        .owl-dt-control-button-content {
          background: none;
        }
      }
    }
  }
}
