@font-face {
  font-family: campton-regular;
  src: url("/assets/fonts/campton-book.otf") format("opentype");
}

@font-face {
  font-family: campton-medium;
  src: url("/assets/fonts/campton-medium.otf") format("opentype");
}

@font-face {
  font-family: campton-bold;
  src: url("/assets/fonts/campton-bold.otf") format("opentype");
}

@font-face {
  font-family: campton-semibold;
  src: url("/assets/fonts/campton-semibold.otf") format("opentype");
}

@font-face {
  font-family: Arial;
  src: url("/assets/fonts/arial.ttf") format("opentype");
}
