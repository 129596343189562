/* This file import all partials for the project */
@import "variables";
@import "material";
@import "typography";
@import "global";
@import "fonts";
@import "utility-classes";
@import "ng-pick-datetime";

@import "~video.js/dist/video-js.css";

/* Common styles used in every pages. */

.list-pages-title {
  font-family: $font-campton-semibold;
  font-size: $max-large-text;
  line-height: 1.13;
  text-align: left;
  margin: 0px;
}

.empty-list-page-subtitle {
  font-family: $font-campton-bold;
  font-size: $max-large-text;
  line-height: 1.13;
  text-align: center;
}

.campton-medium-min-large-text {
  font-family: $font-campton-medium;
  font-size: $min-large-text;
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-align: left;
}

.arial-max-small-text {
  width: 432px;
  height: 16px;
  font-family: Arial;
  font-size: $max-small-text;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.arial-min-small-text {
  width: 165px;
  height: 15px;
  font-family: Arial;
  font-size: $min-small-text;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: $secondary-dark-cool-gray;
}

.page-padding {
  padding-left: 39px;
  padding-right: 38px;
}

.separation-pipe {
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 600px;
}

.header-footer-padding {
  height: 65px !important;
  padding-left: 39px !important;
  padding-right: 38px !important;
}

.search-heading {
  height: 17px;
  font-family: $font-campton-semibold;
  font-size: $min-medium-text;
  line-height: 1.5;
  text-align: left;
}

.back-to-page {
  font-weight: bold;
  color: $primary-action;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  text-decoration: none;
}

.table-form-field-no-validation {
  .mat-form-field-wrapper {
    padding: 0px;
  }
}

mat-cell {
  padding: 10px;
}

.mat-checkbox {
  margin-right: 20px;

  .mat-checkbox-inner-container {
    height: 30px;
    width: 30px;
    background-color: $white;
    border-radius: 3px;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 32px;
  }

  .mat-checkbox-label {
    font-family: Arial;
    font-size: $max-small-text;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue;
  }
}

.min-height {
  min-height: calc(100vh - 65px);
}
.main-div {
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 52px;
  .div-table {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .row {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
  }
  .mat-div {
    .mat-inline {
      display: inline-block;
    }
  }
}

.data-found-div {
  margin: 36px 26px 0px 25px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 43%;
  left: 33%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.video-js.playlist .vjs-big-play-button {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: 300%;
  padding: 0;
  left: 50%;
  top: 50%;
  /* border:none; */
  transform: translate(-50%, -50%);
}

.video-js.playlist {
  margin: 1em;
}

.vjs-control-bar {
  background-color: $dark-blue !important;
}

.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
  display: flex;
}

.video-js.playlist .vjs-big-play-button {
  visibility: hidden;
}

.mat-snack-bar-container {
  background: $dark-blue;
  min-width: 458px !important;
  span {
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background: $white;
}

.playback {
  .retakes-container {
    border: 1px solid $blue;
    .retake-section {
      .count {
        background-color: $blue !important;
      }
    }
  }
}

.template-detail-field-margin {
  div {
    margin: 5px 0px;
    width: auto;
  }
  .color {
    color: $secondary-dark-cool-gray;
  }
}
