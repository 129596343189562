/*  You can add global styles to this file */

@include mat-core();

html,
body {
  height: 100%;
}

body {
  background-color: $body-bg-color;
  font-family: $body-font;
  color: $body-color;
  margin: 0;
}

a {
  cursor: pointer;
  color: $primary-action;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.page-wrap {
  box-sizing: border-box;
  /* padding: 1rem; */
  background-color: $page-wrap-bg-color;
  min-height: calc(100vh - 160px);

  @media (min-width: 576px) {
    min-height: calc(100vh - 130px);
  }
}

.container {
  display: block;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 288px;
}
@media (min-width: 600px) {
  .container {
    max-width: 302px;
  }
}
@media (min-width: 660px) {
  .container {
    max-width: 584px;
  }
}
@media (min-width: 940px) {
  .container {
    max-width: 864px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1146px;
  }
}

h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

mat-card {
  margin-top: 1rem;
}

fnx-icon {
  display: inline-block;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.mat-horizontal-stepper-header-container {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0 18%;
}

.mat-step-header .mat-step-icon {
  background: white;
  border: 3px solid #c7c1bb;
  color: #c7c1bb;
}

.mat-step-header .mat-step-icon-selected {
  color: white;
}

.mat-step-label-selected {
  color: #162744 !important;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  font-family: $font-campton-bold;
  color: #c7c1bb;
  padding: 4px 0 0 0 !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  border: solid 3px #3ca8de;
  background-color: #3ca8de;
}

.mat-step-header {
  cursor: inherit;
}

.mat-horizontal-stepper-header {
  pointer-events: none;
}

.icon-input-form {
  position: relative;
  margin-left: 40px;
  min-width: 100px;
  max-width: 160px !important;

  .calendar-button {
    position: absolute;
    left: -60px;
    font-size: 17px;
    top: 1px;
    color: $white;
    cursor: pointer;
    background-color: $primary-action;
    width: 43px;
    height: 43px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
