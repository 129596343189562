/*
*   Media query breakpoints
*/
@import "fonts";

$small: 660;
$compact: 800;
$medium: 940;
$large: 1200;
$Xlarge: 1600;
$min-small: #{$small}px;
$min-compact: #{$compact}px;
$min-medium: #{$medium}px;
$min-large: #{$large}px;
$min-Xlarge: #{$Xlarge}px;

$max-small: #{$small - 1}px;
$max-compact: #{$compact - 1}px;
$max-medium: #{$medium - 1}px;
$max-large: #{$large - 1}px;
$max-Xlarge: #{$Xlarge - 1}px;

/* Font sizes for the UI. */
$min-small-text: 13px;
$max-small-text: 14px;
$min-medium-text: 16px;
$max-medium-text: 18px;
$min-large-text: 20px;
$large-text: 22px;
$max-large-text: 32px;

/* Font families */
$font-verlag: "Verlag A", Roboto, "Helvetica Neue", sans-serif;
$font-verdana: Verdana, Roboto, "Helvetica Neue", sans-serif;
$font-arial: Arial, Helvetica, sans-serif;
$font-campton-regular: campton-regular;
$font-campton-medium: campton-medium;
$font-campton-semibold: campton-semibold;
$font-campton-bold: campton-bold;
$font-campton: "Campton", "Poppins", sans-serif;

/* font application */
$body-font: $font-campton-regular;
$base-font-family: $font-campton-regular;
$headline-font-family: $font-campton-semibold;

/*  Theme Colors*/
$light-brown: #f4f0ec;
$secondary-brown: #c7c1bb;
$dark-blue: #162744;
$dark-grey: #2d2c2b;
$very-light-grey: #0000000a;
$secondary-dark-cool-gray: #958e86;
$mid-grey: #9c9997;
$mid-dark-grey: #7a7a7a;
$light-grey: #c8c5c2;
$lighter-grey: #f4f0ec;
$blue: #3ca8de;
$mid-blue-opacity50: #5da5d980;
$green: #6bbe74;
$light-green: #92d2d5;
$red: #e24723;
$light-red: #f0c7bd;
$yellow: #e6b40c;
$light-yellow: #f0e1bd;
$black: #000000;
$white: #fff;
$alert-background-success: #b9d9bd;
$new-light-blue: #c1cbea;
$light-sky-blue: #79c3e8;
$light-silver: #d6d8dc;
$mid-silver: #f9f7f6;
$nero: #1f1f1f;
$secondary-yellow: #fcc50d;
$light-blue: #a4d6ef;

/*
*   Color application
*/

$primary-color: $blue;
$secondary-color: $dark-blue;
$official-grey-color: $light-brown;

/* Sections */
$body-bg-color: $light-brown;
$body-color: $dark-blue;
$header-active-link-color: $light-brown;
$header-toolbar-bg-color: $white;
$footer-bg-color: $light-brown;
$footer-color: $white;
$page-wrap-bg-color: $lighter-grey;
$privacy-policy-bg: $white;
$sub-section-group-bg: $lighter-grey;
$header-link-color: $black;
$footer-card-bg-color: $white;
$expanded-header-bg-color: $dark-blue;
$list-element-type-bg: $yellow;
$list-element-type-color: $white;
$state-label-grey: $dark-grey;
$shortcuts-bg-color: $light-brown;

/* Texts */
$copyright-color: $light-grey;
$text-data-color: $green;
$text-error-color: $red;
$text-warning-color: $yellow;
$text-disabled-color: $lighter-grey;
$text2-disabled-color: $light-grey;
$text-on-colored-bg: $white;
$text-shadowed-color: $mid-grey;
$text-label-grey-color: $mid-grey;
$text-watermark-color: $white;
$text-pending-color: $light-green;
$text-blue-color: $dark-blue;
$text-light-brown: $secondary-brown;
$text-black: $black;

/* Icons */
$text-action-icon-color: $white;
$text-indication-icon-color: $light-grey;
$text-indication-icon-color-secondary: $secondary-dark-cool-gray;
$icon-color-secondary: $secondary-dark-cool-gray;
$icon-dark-blue: $dark-blue;

/* buttons and links */
$primary-action: $blue;
$action-icon-bg-color: $primary-action;
$action-icon-light-bg-color: $white;
$action-icon-disabled-bg-color: $light-brown;
$disabled-btn-bg-color: $light-grey;
$disabled-btn-color: $lighter-grey;
$action-icon-color-hover: $dark-blue;
$select-take-bg-color: $light-blue;

/* borders */
$gray-border: 1px solid $light-grey;
$dark-blue-border: 1px solid $dark-blue;
$dark-blue-border2: 1px solid $dark-blue;
$form-outline-border-color: $secondary-dark-cool-gray;

/* Hovers */
$card-hover-bg-color: $lighter-grey;
$tile-hover-state: $very-light-grey;

/* Others */
$green-card-bg-color: $green;
$grey-card-bg-color: $lighter-grey;
$success: $green;
$warning: $yellow;
$failure: $red;
$required: $red;

/* fontAwesome button-icons */
$fnx-button-size: 40px;

// status colors
$status-in-queue: $yellow;
$status-started: $light-sky-blue;
$status-completed: $green;
$status-on-hold: $state-label-grey;
$status-canceled: $red;

$retake-bg-color: $mid-silver;
$retake-index-bg-color: $secondary-brown;
$retake-timecode-color: $nero;
