@import "@angular/material/theming";
@import "variables";
/* Plus imports for other components in your app. */

$mat-showcase: (
  100: $lighter-grey,
  500: $blue,
  700: $green,
  A100: $light-grey,
  A500: $light-green,
  A700: $dark-blue,
  W100: $yellow,
  W500: $red,
  W700: $red,
  contrast: (
    100: $mid-grey,
    500: white,
    700: white,
    A100: $mid-grey,
    A500: $mid-grey,
    A700: white,
  ),
);

@function set-mat-fnx-theme-foreground($color) {
  @return (
    base: $color,
    /* Normal text */ divider: rgba($color, 0.12),
    dividers: rgba($color, 0.12),
    disabled: $mid-grey,
    disabled-button: $light-grey,
    disabled-text: $mid-grey,
    elevation: $color,
    hint-text: rgba($color, 0.38),
    secondary-text: $mid-grey,
    /* Disabled checkbox, input placeholder */ icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: $body-color,
    slider-min: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

/* most of the values come from original theme of Material */
@function set-mat-fnx-theme-background($color) {
  @return (
    status-bar: map_get($mat-grey, 300),
    app-bar: map_get($mat-grey, 100),
    background: map_get($mat-grey, 50),
    hover: rgba(black, 0.04),
    card: white,
    dialog: white,
    disabled-button: $lighter-grey,
    raised-button: white,
    focused-button: $dark-focused,
    selected-button: map_get($mat-grey, 300),
    selected-disabled-button: map_get($mat-grey, 400),
    disabled-button-toggle: map_get($mat-grey, 200),
    unselected-chip: map_get($mat-grey, 300),
    disabled-list-option: map_get($mat-grey, 200),
    tooltip: map_get($mat-grey, 700)
  );
}

/* Define the palettes for your theme */
$primary: mat-palette($mat-showcase, 500, 100, 700);
$accent: mat-palette($mat-showcase, A500, A100, A700);
$warn: mat-palette($mat-showcase, W500, W100, W700);

$mat-fnx-theme-foreground: set-mat-fnx-theme-foreground(black);
$mat-fnx-theme-background: set-mat-fnx-theme-background(black);

/* Create the theme object (a Sass map containing all of the palettes). */
$mat-fnx-light-theme: mat-light-theme($primary, $accent, $warn);

$mat-fnx-custom-theme: map-merge(
  $mat-fnx-light-theme,
  (
    foreground: $mat-fnx-theme-foreground,
    background: $mat-fnx-theme-background,
  )
);

/* Include theme styles for core and each component used in your app. */
@include angular-material-theme($mat-fnx-custom-theme);

.mat-toolbar-row {
  height: 80px !important;
}
/*
$custom-typography: mat-typography-config(
    $font-family: "Verlag A"
);

@include mat-core($custom-typography);
*/

.mat-snack-bar-container {
  background: $body-bg-color;
}

.mat-snack-bar-container simple-snack-bar {
  font-size: 16px;
}

.mat-snack-bar-container.error {
  background: $red;
}

.mat-snack-bar-container.success {
  background: $green;
}

.cdk-overlay-pane {
  mat-dialog-container {
    max-height: 100vh;
  }

  mat-dialog-actions {
    display: block;
    margin-bottom: -16px;

    @media (min-width: $min-small) {
      margin-bottom: -24px;
    }
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background: $primary-action;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary-action;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: white;
}

mat-select .mat-select-arrow {
  border: none;
  background: url(/assets/icons/icon_arrow_down.svg);
  width: 14px;
  height: 14px;
}

[mat-stroked-button] {
  border-color: inherit !important;
  mat-icon {
    color: inherit !important;

    &[ng-reflect-svg-icon="details_page"] svg {
      position: relative;
      top: 2px;
    }
  }
}

.mat-raised-button {
  font-family: $font-campton-medium !important;
  font-size: $max-medium-text !important;
  border-radius: 25px !important;
  height: 49px !important;
}
.mat-outline-button {
  font-family: $font-campton-medium !important;
  font-size: $max-medium-text !important;
  border-radius: 25px !important;
  height: 49px !important;
  border: 2px solid $black;
}

button.mat-raised-button[disabled] {
  border: 1px solid $light-grey !important;
}

.mat-simple-snackbar-action .mat-button-wrapper {
  color: $primary-action;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $secondary-dark-cool-gray !important;
  border-radius: 4px !important;
  top: 0.8em !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: 0 !important;
}

/* On mobile, 100vh has some space taken by a top or bottom bar, in the case of a full screen pop up it will cause problems */
body.mobile {
  mat-dialog-container {
    max-height: 80vh !important;
  }
}

snack-bar-container {
  position: relative;
  top: -100px;

  @media (min-width: $min-small) {
    top: -50px;
  }
}
